var validator;

function addValidation(locale = null) {
  let language = "en";
  if (locale) {
    language = locale.substr(0, locale.indexOf("_"));
  }

  /**
   * Form validation
   */
  // use correct language
  localiseValidator(locale);

  $(".form").each(function () {

    validator = $(this).validate({ //This assumes that there is only 1 form in screener (otherwise "validator" would get assigned the the last element with class form)
      lang: language,
      errorPlacement: function (error, element) {
        const elementParent = element.parent();
        error.addClass("error-icon"); // This class prepends the Font Awesome 'exclamation triangle' icon
        if (element.attr("name") == "element_name") {
          error.insertAfter("#element_div_id");
        } else if (elementParent.length && elementParent.hasClass("intl-tel-input")) {
          error.insertAfter(elementParent);
        } else {
          error.insertAfter(element);
        }
      },
      invalidHandler: function (form, validator) {
        if (!validator.numberOfInvalids()) return;

        $("html, body").animate(
          {
            scrollTop: $(validator.errorList[0].element).offset().top - 100
          },
          500
        );
      }
    });

    $(this).on("submit", function (e) {
      e.preventDefault();
      $('.tooltip').remove();
      removeAllAPIAddedErrors();

      if ($(".form__input.error").length > 0 || $(".form__input.error-intl-tel").length > 0 || !$(this).valid()) {
        return false;
      } else {
        submitData();
      }
    });
  });
}

var typingTimer;
var finishedTypingInterval = 200;

$("#questions-form").on("input keyup", ".form__input", function (e) {
  removeAPIAddedErrorFromElement(e);
  clearTimeout(typingTimer);
  typingTimer = setTimeout(function () { finishedTyping(e) }, finishedTypingInterval);
}).on('focusout change propertychange', 'input', e => {
  removeAPIAddedErrorFromElement(e);
  checkIfAllRequiredQuestionsAreAnswered(e);
});

$("#questions-form").on('keydown', function () {
  clearTimeout(typingTimer);
});

function finishedTyping(e) {
  removeAPIAddedErrorFromElement(e);
  checkIfAllRequiredQuestionsAreAnswered(e);
}

$('#questions-form').on("paste", function (e) {
  setTimeout(() => {
    removeAPIAddedErrorFromElement(e);
    checkIfAllRequiredQuestionsAreAnswered(e)
  }, 200);
});

function removeAPIAddedErrorFromElement(element) {
  $('div[id^="--error"]').each(function () {
    if (
      $(this)
        .parent()
        .find(element.target).length > 0
    ) {
      $(this).remove();
    }
  });
}

function removeAllAPIAddedErrors() {
  $('div[id^="--error"]').remove();
}

function disableBottomBarButtons(disable) {
  //Mobile
  $("#fixed-bottom-bar button").attr("disabled", disable);
  //Desktop
  $("#submit").attr("disabled", disable);
  // $("#previous").attr("disabled", disable);
}

function disableBottomBarSubmitButton(disable) {
  //Mobile
  $("#fixed-bottom-bar button")
    .filter(".submit")
    .attr("disabled", disable);
  //Desktop
  $("#submit").attr("disabled", disable);
}

function disablePreviousButton(disable, synexusFlag) {
  // Mobile
  $("#fixed-bottom-bar button").attr("disabled", disable);
  // Desktop
  $("#previous").attr("disabled", disable);
  
  if (synexusFlag) return;
  
  if (disable) {
    $("#previous span").css( { 'color': '#fff' } );
    $("#previous .fas").css( { 'color': '#fff' } );
  } else {
    $("#previous span").css( { 'color': '#283891' } );
    $("#previous .fas").css( { 'color': '#283891' } );
  }
}

function checkIfAllRequiredQuestionsAreAnswered(e) {
  if (e !== undefined && e.target.id === 'gpAutoTextField') return;
  const $questionsForm = $('#questions-form');
  const singleChoiceContainers = $questionsForm.find(".single-choice-answers-container");
  const multipleChoiceContainers = $questionsForm.find(".multiple-choice-answers-container");

  //Check single selection answers:
  for (let i = 0; i < singleChoiceContainers.length; i++) {
    const container = singleChoiceContainers[i];
    const answerIsRequired = $(container)
      .closest(".question-answers-container")
      .attr("data-required");

    if (!container.text && answerIsRequired === "true") {
      disableBottomBarSubmitButton(true);
      return;
    }
  }

  //Check multiple selection answers:
  for (let i = 0; i < multipleChoiceContainers.length; i++) {
    const container = $(multipleChoiceContainers[i]);
    const answerIsRequired = container.closest(".question-answers-container").attr("data-required");
    if ((container.data("values") === undefined || container.data("values").length === 0) && answerIsRequired === "true") {
      disableBottomBarSubmitButton(true);
      return;
    }
  }

  //Check input fields:
  let formIsValid;
  if ($questionsForm) {
    formIsValid = $questionsForm.validate({
      rules: {
        phone: {
          phoneUS: true
        }
      }
    })
      .checkForm();

    $questionsForm.find('input[type="text"]').each(function () {
      if ($(this).attr('pattern')) {
        let patternRegex = new RegExp(`^${$(this).attr('pattern')}$`, 'i')
        if (!patternRegex.test($(this).val())) {
          formIsValid = false;
        }
      }
    })
  }


  $questionsForm.find('input[name*=Date], input[name*=date]').each(function () {
    const minLength = $(this).closest(".question-answers-container").data('answer-type') === "TEXT_MM_YYYY" ? 6 : 8
    if ($(this).val().length < minLength) formIsValid = false;
  })

  //Check if there are any error labels displayed
  const visibleErrorLabels = $questionsForm.find(".required-answer-flag").filter(":visible");
  if (
    visibleErrorLabels.length > 0 ||
    $(".form__input.error").length > 0 ||
    $(".form__input.error-intl-tel").length > 0
  ) {
    formIsValid = false; //reuse form variable
  }

  disableBottomBarSubmitButton(!formIsValid);
}

function localiseValidator(locale) {
  if (validatorLanguageStrings[locale]) {
    jQuery.extend(jQuery.validator.messages, validatorLanguageStrings[locale]);
  } else {
    //locale not found try and find alternative (cannot use helper function)
    const allCountryTranslations = Object.keys(validatorLanguageStrings); //every language available for this 'type'
    const localeCountry = locale.split('_')[0];
    const regex = RegExp(localeCountry);
    const matchingCountryLocale = allCountryTranslations[allCountryTranslations.findIndex(locale => regex.test(locale))]; //string of a similar language in the same country

    let validatorLanguageStringsObj = validatorLanguageStrings["en_US"];
    if (matchingCountryLocale) {
      validatorLanguageStringsObj = validatorLanguageStrings[matchingCountryLocale];  //a next best alternative
    }

    jQuery.extend(jQuery.validator.messages, validatorLanguageStringsObj);
  }
}

//Generic reusable validation methods start here:

function addValidationGeneric(form, callback, confirmPasswordElement) {
  let validator = $(form).validate({
    rules: {
      password: {
        required: true,
        passwordCheck: true,
        minlength: 8
      },
      password2: {
        required: true,
        equalTo: confirmPasswordElement
      }
    },
    errorPlacement: function (error, element) {
      const elementParent = element.parent();

      if (element.attr("name") == "element_name") {
        error.insertAfter("#element_div_id");
      } else if (elementParent.length && elementParent.hasClass("intl-tel-input")) {
        error.insertAfter(elementParent);
      } else {
        error.insertAfter(element);
      }
    },
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids()) return;
      $("html, body").animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 100
        },
        500
      );
    }
  });

  $.validator.addMethod("passwordCheck", function (value) {
    $.validator.messages.passwordCheck = "Password does not meet the specified requirements";
    return /[a-z]/.test(value) // has a lowercase letter
      && /[A-Z]/.test(value) // has a uppercase letter
      && /\d/.test(value) // has a digit
      && /[*@!#%&()^~{}]+/.test(value) // has at least one special digit
  });

  $(form).on("submit", function (e) {
    e.preventDefault();

    // this is a separate check for dnis, i think the way this has been set up doesnt work with validation except on the first input
    // also the submit button is manually enabled disabled on pii so kinda the same way
    if (e.currentTarget.classList.contains('dnis-form')) {
      if (checkDNISFields() == false) {
        return;
      }
    }

    if ($(".form__input.error").length > 0 || $(".form__input.error-intl-tel").length > 0 || !$(this).valid()) {
      return false;
    } else {
      callback();
    }
  });

  return validator;
}

function checkDNISFields() {
  const intIDInput = $('#interactionIDInput');
  const allowedChars = new RegExp(/^[A-Za-z0-9\-\.]*$/gm);

  // check just incase this somehow gets called and it doesnt exist
  if (intIDInput == null) {
    return true;
  }
  else {
    if (intIDInput.val().length == 0 || intIDInput.val().length > 256 || !allowedChars.test(intIDInput.val())) {
      addValidationErrorToElement($('#interactionIDInput'));
      return false;
    }
  }

  return true;
}

function addValidationErrorToElement(ele) {
  $(ele).addClass('error');

  if ($(ele).parent().find('#interactionIDInput-error, #dnis-input-error').length == 0) {
    ele[0].parentNode.insertAdjacentHTML('beforeend', '<label id="dnis-input-error" class="error" for="dnis-input">This field is required to have 1-256 characters and no special characters except - and ..</label>');
  }
}

$(document).on('click', '#interactionIDInput', () => {
  $('#interactionIDInput').parent().find('#interactionIDInput-error, #dnis-input-error').each(function (i) {
    $(this).remove();
  });
});